import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignWVRoutingModule } from './signwv-routing.module';
import { SignWVComponent } from './signwv.component';
import {SharedModule } from '../../../@core/components/shared.module'


@NgModule({
  declarations: [SignWVComponent],
  imports: [
    CommonModule,
    SignWVRoutingModule,
    SharedModule
  ]
})
export class SignWVModule { }
