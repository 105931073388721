import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WvTemplatesComponent} from './wv-templates.component'
const routes: Routes = [
  {
    path: '',
    component: WvTemplatesComponent
  },
  {
    path: ':id',
    component: WvTemplatesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WvTemplatesRoutingModule { }
