import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewerRoutingModule } from './viewer-routing.module';
import { ViewerComponent } from './viewer.component';
import { SharedModule } from '../../@core/components/shared.module';

@NgModule({
  declarations: [ViewerComponent],
  imports: [
    CommonModule,
    ViewerRoutingModule,
    SharedModule
  ]
})
export class ViewerModule { }
