import { Component } from '@angular/core';

@Component({
	selector: 'ngx-miscellaneous',
	template: `
    <router-outlet></router-outlet>
  `,
})
export class MiscellaneousComponent {
}
