import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WvTemplatesRoutingModule } from './wv-templates-routing.module';
import { WvTemplatesComponent } from './wv-templates.component';
import {SharedModule } from '../../../@core/components/shared.module'

@NgModule({
  declarations: [WvTemplatesComponent],
  imports: [
    CommonModule,
    WvTemplatesRoutingModule,
    SharedModule
    
  ]
})
export class WvTemplatesModule { }
