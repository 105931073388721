import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-wv-editor-completed',
  templateUrl: './wv-editor-completed.component.html',
  styleUrls: ['./wv-editor-completed.component.scss']
})
export class WvEditorCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
receiveButtonClick(data){

}
}
