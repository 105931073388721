import { Injectable } from '@angular/core';
import { DocumentService } from '../http/document.service';
import { Documents } from '../http/interfaces/documents';

@Injectable({
  providedIn: 'root'
})
export class FileMetaService {
public documentDetails;
  constructor(
    private documentService: DocumentService
  ) { }
  getDocumentDetails(documentId) {
    var result : Documents;
		this.documentService.getDocumentDetails(documentId).subscribe(
			userMeta => {
        result = userMeta;
        this.documentDetails = userMeta;
        console.log('get document details service');
        console.log(result);
			err => console.error(err)
      }
    );
    return this.documentDetails;
  }

}
