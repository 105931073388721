import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebviewerSignService } from '../../../@core/components/webviewer-final/webviewer-sign/webviewer-sign.service'
import {Documents} from '../../../@core/services/http/interfaces/documents'
@Component({
  selector: 'ngx-signwv',
  templateUrl: './signwv.component.html',
  styleUrls: ['./signwv.component.scss']
})
export class SignWVComponent implements OnInit {
  documentId
  documentData:Documents[]
  documentMembers;

  constructor(
    private route: ActivatedRoute,
    private wvSignService: WebviewerSignService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.documentId = params.get('id');
    });
  }

  ngOnInit() {
    //this.wvSignService.currentDocumentData.subscribe(message => this.documentData = message)
    //console.log(this.documentData[0].providerResponse.location);

  }
  getDocument(data) {
    this.documentData = data;
    this.wvSignService.changeDocumentData(data)
    console.log(data);
  }
  getContactDetail(data) {
    this.documentMembers = data;
    this.documentMembers.savedSignatures = {};
    this.documentMembers.savedSignatures.fullSignature = '';
    this.documentMembers.savedSignatures.initials = '';
    this.wvSignService.changeMemberData(this.documentMembers);
    console.log(data);
  }
  // getMembers(data) {
  //       //this.documentMembers = data;
  //       this.wvSignService.changeDocumentMembers(data)
  //     }

}
