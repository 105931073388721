import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebviewerEditorService } from '../../../@core/components/webviewer-final/webviewer-editor/webviewer-editor.service';

@Component({
  selector: 'ngx-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  public documentId =null;
  public documentData=null;
  public documentMembers= null;
  public myContactInfo=null;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wvEditorService: WebviewerEditorService
  ) {
    this.route.paramMap.subscribe(params => {
      this.documentId = params.get('id');
    });
  }

  ngOnInit() {
  }
  getDocument(data) {
    this.documentData = data;
    switch(this.documentData.status ){
      case 'draft':
        this.wvEditorService.changeDocumentData(data)
        this.wvEditorService.mimeType = data.type;
      break;
      default:
      alert('Document NOT Draft Functionality will be to send a confirm and open View Only Mode. For now it will redirect to the document list view of status')
      this.router.navigate(['/documents/', this.documentData.status ]);
      break;
    }
    
    
  }
  getMembers(data) {
    this.documentMembers = data;
    this.wvEditorService.changeDocumentMembers(data)
  }
  getMyContactInfo(data){
    this.myContactInfo = data;
    this.wvEditorService.changeMyContactInfo(data);
  }
}
