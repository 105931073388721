import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebviewerTemplateService } from '../../../@core/components/webviewer-final/webviewer-template/webviewer-template.service'

@Component({
  selector: 'ngx-wv-templates',
  templateUrl: './wv-templates.component.html',
  styleUrls: ['./wv-templates.component.scss']
})
export class WvTemplatesComponent implements OnInit {
  public documentId;
  public documentData;
  public documentMembers = [{
    id:'Sender',
    email: 'Sender',
    order: 0,
    sort: 0
  },{
    id:'Receiver',
    email: 'Receiver',
    order: 1,
    sort: 1
  }];
  public myContactInfo;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wvTemplateService: WebviewerTemplateService
  ) {
    this.route.paramMap.subscribe(params => {
      this.documentId = params.get('id');
    });
  }

  ngOnInit() {
  }
  getDocument(data) {
    this.documentData = data;
    switch(this.documentData.status ){
      case 'draft':
        this.wvTemplateService.changeDocumentData(data)
      break;
      default:
      this.wvTemplateService.changeDocumentData(data)
      // alert('Document NOT Draft Functionality will be to send a confirm and open View Only Mode. For now it will redirect to the document list view of status')
      // this.router.navigate(['/documents/', this.documentData.status ]);
      break;
    }
    
    
  }
  getMembers(data) {
    this.documentMembers = data;
    this.wvTemplateService.changeDocumentMembers(data)
  }
  getMyContactInfo(data){
    this.myContactInfo = data;
    this.wvTemplateService.changeMyContactInfo(data);
  }

}
