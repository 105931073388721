import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditorComponent } from './editor.component';
import { WvEditorCompletedComponent} from '../../../@core/components/webviewer-final/wv-editor-completed/wv-editor-completed.component';

const routes: Routes = [
  {
    path: '',
    component: EditorComponent
  },
  {
    path: 'sent',
    component: WvEditorCompletedComponent
  },
  {
    path: ':id',
    component: EditorComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditorRoutingModule { }
