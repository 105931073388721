import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router'
import { FileMetaService } from '../../@core/services/common/file-meta.service';
import { Documents } from '../../@core/services/http/interfaces/documents';
import {UserData} from '../../@core/data/users'
import { FormrlyUser } from '../../@core/services/http/interfaces/formrly-users';

@Component({
  selector: 'ngx-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, AfterViewInit {
  public fileMetaId;
  public fileMeta: Documents;
  public fileMeta2;
  public userDetails: FormrlyUser;
  public viewMode = 'editor';
  

  constructor(
    private route:ActivatedRoute,
    private fileMetaService: FileMetaService,
    private userSrvc: UserData
  ) { 
    // this.route.paramMap.subscribe(params => {
		// 	this.fileMetaId = params.get('id');
    // });
    
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
			this.fileMetaId = params.get('id');
    });
    //this.getFileMeta(this.fileMetaId);
    //MRR
    //this.fileMeta2 = this.fileMetaService.getDocumentDetails(this.fileMetaId);
    this.getCurrentUser();
    console.log('ngOninit Viewer');
  }
  ngAfterViewInit(){
    console.log('ngAfterViewInit Viewer')
  }

  ngOnChanges(){
    console.log('ngOnChanges Viewer')
  }

  getDocument(data){
    this.fileMeta = data;
    console.log('fileMetaSet');
    console.log(this.fileMeta);
  }
  //getCurrentUser(data){
  //  this.userMeta = data;
  //  console.log('userMetaSet');
  //  console.log(this.userMeta);
  //}
  getCurrentUser(){
    this.userSrvc.getCurrentUser().subscribe(
      userMeta => {
        this.userDetails = userMeta;
        console.log('getCurrentUser')
        console.log(this.userDetails);
      },
      err => console.log(err)
    );
  }
}
