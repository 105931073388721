import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditorRoutingModule } from './editor-routing.module';
import { EditorComponent } from './editor.component';
import { WvEditorCompletedComponent} from '../../../@core/components/webviewer-final/wv-editor-completed/wv-editor-completed.component';
import {SharedModule } from '../../../@core/components/shared.module'

@NgModule({
  declarations: [EditorComponent, WvEditorCompletedComponent],
  imports: [
    CommonModule,
    EditorRoutingModule,
    SharedModule
  ]
})
export class EditorModule { }
